import { createSlice } from '@reduxjs/toolkit';
import { createNewSetting, deleteSetting, fetchAllSetting, updateSetting } from '../services/settingService';

const initialState = {
    data: [],
    paginate: null,
    currentSetting: null,
    loading: false,
    error: null,
    newSetting: null
};

export const settingSlice = createSlice({
    name: 'setting',
    initialState,
    reducers: {
        setCurrentSetting: (state, action) => {
            state.currentSetting = { ...action.payload };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllSetting.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchAllSetting.fulfilled, (state, action) => {
                // state.loadStatus = true;
                state.loading = false;

                const newData = { ...action.payload.data };
                state.data = [...newData.data];
                delete newData.data;
                state.paginate = newData;
            })
            .addCase(fetchAllSetting.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(createNewSetting.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(createNewSetting.fulfilled, (state, action) => {
                state.loading = false;
                state.newSetting = action.payload.data;
                state.error = null;
            })
            .addCase(createNewSetting.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.response.data;
            })
            .addCase(updateSetting.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(updateSetting.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
            })
            .addCase(updateSetting.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.response.data;
            })
            .addCase(deleteSetting.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(deleteSetting.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
            })
            .addCase(deleteSetting.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.response.data;
            })
    }
});

export const { setCurrentSetting } = settingSlice.actions;
export default settingSlice.reducer;