import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const IndexMemberPage = lazy(() => import('../../container/pages/MemberPage/IndexMemberPage'));
const AddMemberPage = lazy(() => import('../../container/pages/MemberPage/AddMemberPage'));
const EditMemberPage = lazy(() => import('../../container/pages/MemberPage/EditMemberPage'));
// const MapResultPage = lazy(() => import('../../container/pages/MemberPage/MapResultPage'));
const BankPage = lazy(() => import('../../container/pages/MemberBankPage/MemberBankPage'));

function MemberRoute() {
  return (
    <Routes>
      <Route path="index" element={<IndexMemberPage />} />
      <Route path="add" element={<AddMemberPage />} />
      <Route path="edit" element={<EditMemberPage />} />
      {/* <Route path="map-result" element={<MapResultPage />} /> */}
      <Route path="bank" element={<BankPage />} />
      {/* <Route path="*" element={<NotFound />} /> */}
    </Routes>
  );
}

export default MemberRoute;
