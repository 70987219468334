import { createAsyncThunk } from "@reduxjs/toolkit";
import { DataService } from "../config/dataService/dataService";

export const fetchAllDistrict = createAsyncThunk(
    "district/fetch",
    async (filter, { rejectWithValue }) => {
        try {
            let url = '/district'
            if (filter && filter !== '') {
                url += '?' + filter;
            }

            const response = await DataService.get(url);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }  
)

export const createNewDistrict = createAsyncThunk(
    "district/create",
    async ({ data }, { rejectWithValue }) => {
        try {
            const response = await DataService.post('/district', data);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }  
)

export const updateDistrict = createAsyncThunk(
    "district/update",
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const response = await DataService.patch('/district/' + id, data);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }  
)

export const deleteDistrict = createAsyncThunk(
    "district/delete",
    async ({ id }, { rejectWithValue }) => {
        try {
            const response = await DataService.delete('/district/' + id);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }  
)