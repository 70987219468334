import { Spin } from 'antd';
import React, { Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import Pages from './pages';
import Address from './address';
import RecipientAddress from './recipient';
import Member from './member';
import withAdminLayout from '../../layout/withAdminLayout';

const Admin = React.memo(() => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Routes>
        <Route index path="/*" element={<Pages />} />
        <Route index path="address/*" element={<Address />} />
        <Route index path="recipient/*" element={<RecipientAddress />} />
        <Route index path="member/*" element={<Member />} />
        {/* <Route path="pages/*" element={<Pages />} /> */}
      </Routes>
    </Suspense>
  );
});

export default withAdminLayout(Admin);
