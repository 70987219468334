import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const ProvincePage = lazy(() => import('../../container/pages/AddressPage/ProvincePage'));
const DistrictPage = lazy(() => import('../../container/pages/AddressPage/DistrictPage'));
const SubDistrictPage = lazy(() => import('../../container/pages/AddressPage/SubDistrictPage'));

function AddressRoute() {
  return (
    <Routes>
      <Route path="province" element={<ProvincePage />} />
      <Route path="district" element={<DistrictPage />} />
      <Route path="sub-district" element={<SubDistrictPage />} />
      {/* <Route path="*" element={<NotFound />} /> */}
    </Routes>
  );
}

export default AddressRoute;
