import { createAsyncThunk } from "@reduxjs/toolkit";
import { DataService } from "../config/dataService/dataService";

export const uploadTemplate = createAsyncThunk(
    "upload/template",
    async ({ file }, { rejectWithValue }) => {
        try {
            const url = '/upload/template';
            const response = await DataService.post(url, { file: file });
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)