import { createAsyncThunk } from "@reduxjs/toolkit";
import { DataService } from "../config/dataService/dataService";

export const fetchAllProvince = createAsyncThunk(
    "province/fetch",
    async (filter, { rejectWithValue }) => {
        try {
            let url = '/province'
            if (filter) {
                url += '?' + filter;
            }

            const response = await DataService.get(url);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }  
)

export const createNewProvince = createAsyncThunk(
    "province/create",
    async ({ data }, { rejectWithValue }) => {
        try {
            const response = await DataService.post('/province', data);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }  
)

export const updateProvince = createAsyncThunk(
    "province/update",
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const response = await DataService.patch('/province/' + id, data);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }  
)

export const deleteProvince = createAsyncThunk(
    "province/delete",
    async ({ id }, { rejectWithValue }) => {
        try {
            const response = await DataService.delete('/province/' + id);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }  
)