import { createSlice } from "@reduxjs/toolkit"
import { copyReciptientAddress, createNewReciptientAddress, deleteReciptientAddress, fetchAllReciptientAddress, updateReciptientAddress } from "../services/centralCodAddressService";

const initialState = {
    data: [],
    paginate: null,
    currentRecipientAddress: null,
    loading: false,
    error: null,
    newRecipientAddress: null
}

const centralCodAddressSlice = createSlice({
    name: 'recipientAddress',
    initialState,
    reducers: {
        setCurrentRecipientAddress: (state, action) => {
            state.currentRecipientAddress = {...action.payload};
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllReciptientAddress.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchAllReciptientAddress.fulfilled, (state, action) => {
                // state.loadStatus = true;
                state.loading = false;

                const newData = { ...action.payload.data };
                state.data = [...newData.data];
                delete newData.data;
                state.paginate = newData;
            })
            .addCase(fetchAllReciptientAddress.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(createNewReciptientAddress.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(createNewReciptientAddress.fulfilled, (state, action) => {
                state.loading = false;
                state.newRecipientAddress = action.payload.data;
                state.error = null;
            })
            .addCase(createNewReciptientAddress.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.response.data;
            })
            .addCase(updateReciptientAddress.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(updateReciptientAddress.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
            })
            .addCase(updateReciptientAddress.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.response.data;
            })
            .addCase(deleteReciptientAddress.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(deleteReciptientAddress.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
            })
            .addCase(deleteReciptientAddress.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.response.data;
            })
            .addCase(copyReciptientAddress.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(copyReciptientAddress.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
            })
            .addCase(copyReciptientAddress.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.response.data;
            })
    }
});

export const { setCurrentRecipientAddress } = centralCodAddressSlice.actions;
export default centralCodAddressSlice.reducer;