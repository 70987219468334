import { createAsyncThunk } from "@reduxjs/toolkit";
import { DataService } from "../config/dataService/dataService";

export const fetchAllMember = createAsyncThunk(
    "member/fetch",
    async (filter, { rejectWithValue }) => {
        try {
            let url = '/member';
            if (filter) {
                url += '?' + filter;
            }

            const response = await DataService.get(url);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }  
)

export const createNewMember = createAsyncThunk(
    "member/create",
    async ({ data }, { rejectWithValue }) => {
        try {
            const response = await DataService.post('/member', data);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }  
)

export const updateMember = createAsyncThunk(
    "member/update",
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const response = await DataService.patch('/member/' + id, data);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }  
)

export const deleteMember = createAsyncThunk(
    "member/delete",
    async ({ id }, { rejectWithValue }) => {
        try {
            const response = await DataService.delete('/member/' + id);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }  
)