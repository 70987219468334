import { createAsyncThunk } from "@reduxjs/toolkit";
import { DataService } from "../config/dataService/dataService";

export const fetchAllSubDistrict = createAsyncThunk(
    "sub-district/fetch",
    async (filter, { rejectWithValue }) => {
        try {
            let url = '/sub-district'
            if (filter && filter !== '') {
                url += '?' + filter;
            }

            const response = await DataService.get(url);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }  
)

export const createNewSubDistrict = createAsyncThunk(
    "sub-district/create",
    async ({ data }, { rejectWithValue }) => {
        try {
            const response = await DataService.post('/sub-district', data);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }  
)

export const updateSubDistrict = createAsyncThunk(
    "sub-district/update",
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const response = await DataService.patch('/sub-district/' + id, data);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }  
)

export const deleteSubDistrict = createAsyncThunk(
    "sub-district/delete",
    async ({ id }, { rejectWithValue }) => {
        try {
            const response = await DataService.delete('/sub-district/' + id);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }  
)

export const activeAreaStatus = createAsyncThunk(
    "sub-district/active-area-status",
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const response = await DataService.patch('/area-status/' + id, data);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }  
)