import { createSlice } from '@reduxjs/toolkit';
import { createNewBank, deleteBank, fetchAllBanks, updateBank } from '../services/bankService';

const initialState = {
    data: [],
    paginate: null,
    currentBank: null,
    loading: false,
    error: null,
    newBank: null
};

export const bankSlice = createSlice({
    name: 'bank',
    initialState,
    reducers: {
        setCurrentBank: (state, action) => {
            state.currentBank = {...action.payload};
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllBanks.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchAllBanks.fulfilled, (state, action) => {
                // state.loadStatus = true;
                state.loading = false;

                const newData = { ...action.payload.data };
                state.data = [...newData.data];
                delete newData.data;
                state.paginate = newData;
            })
            .addCase(fetchAllBanks.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(createNewBank.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(createNewBank.fulfilled, (state, action) => {
                state.loading = false;
                state.newBank = action.payload.data;
                state.error = null;
            })
            .addCase(createNewBank.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.response.data;
            })
            .addCase(updateBank.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(updateBank.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
            })
            .addCase(updateBank.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.response.data;
            })
            .addCase(deleteBank.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(deleteBank.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
            })
            .addCase(deleteBank.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.response.data;
            })
    }
});

export const { setCurrentBank } = bankSlice.actions;
export default bankSlice.reducer;