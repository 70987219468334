import { createAsyncThunk } from "@reduxjs/toolkit";
import { DataService } from "../config/dataService/dataService";

export const login = createAsyncThunk(
    "auth/login",
    async ({ data }, { rejectWithValue }) => {
        try {
            const response = await DataService.publicPost('/auth/login', { ...data, role: 1 });
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)