import { createAsyncThunk } from "@reduxjs/toolkit";
import { DataService } from "../config/dataService/dataService";

export const fetchAllReciptientAddress = createAsyncThunk(
    "cod-address/fetch",
    async (filter, { rejectWithValue }) => {
        try {
            let url = '/recipient-address'
            if (filter && filter !== '') {
                url += '?' + filter;
            }

            const response = await DataService.get(url);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }  
)

export const createNewReciptientAddress = createAsyncThunk(
    "cod-address/create",
    async ({ data }, { rejectWithValue }) => {
        try {
            const response = await DataService.post('/recipient-address', data);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }  
)

export const updateReciptientAddress = createAsyncThunk(
    "cod-address/update",
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const response = await DataService.patch('/recipient-address/' + id, data);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }  
)

export const deleteReciptientAddress = createAsyncThunk(
    "cod-address/delete",
    async ({ id }, { rejectWithValue }) => {
        try {
            const response = await DataService.delete('/recipient-address/' + id);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }  
)

export const copyReciptientAddress = createAsyncThunk(
    "cod-address/copy",
    async ({ id, memberId }, { rejectWithValue }) => {
        try {
            const response = await DataService.post('/recipient-address/copy', { id, memberId });
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }  
)