import { createSlice } from "@reduxjs/toolkit"
import { searchPlace } from "../services/placeService";

const initialState = {
    data: [],
    loading: false,
    error: null,
}

const placeSlice = createSlice({
    name: 'place',
    initialState,
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder.addCase(searchPlace.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(searchPlace.fulfilled, (state, action) => {
            state.loading = false;
            const newData = { ...action.payload.data };
            state.data = [...newData.data];
            delete newData.data;
        })
        .addCase(searchPlace.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
    }
});

export default placeSlice.reducer;