import { createSlice } from "@reduxjs/toolkit"
import { uploadTemplate } from "../services/uploadService";

const initialState = {
    loading: false,
    error: null,
}

export const uploadSlice = createSlice({
    name: 'upload',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(uploadTemplate.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(uploadTemplate.fulfilled, (state, action) => {
            state.loading = false;
        })
        .addCase(uploadTemplate.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
    }
});

export default uploadSlice.reducer;