import { createSlice } from "@reduxjs/toolkit"
import { overviewShipping } from "../services/shippingService";

const initialState = {
    isLoadSuccess: false,
    overviewData: [],
    loading: false,
}

const mainSlice = createSlice({
    name: 'main',
    initialState,
    reducers: {
        setIsLoadSuccess: (state, action) => {
            state.isLoadSuccess = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(overviewShipping.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(overviewShipping.fulfilled, (state, action) => {
                state.loading = false;
                // const newData = { ...action.payload.data };
                state.overviewData = { ...action.payload.data };
            })
            .addCase(overviewShipping.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
    }
});

export const { setIsLoadSuccess } = mainSlice.actions;
export default mainSlice.reducer;