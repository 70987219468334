import { createSlice } from "@reduxjs/toolkit";
import { createNewMember, deleteMember, fetchAllMember, updateMember } from "../services/memberService";

const initialState = {
    data: [],
    paginate: null,
    currentMember: null,
    loading: false,
    error: null,
    newMember: null
};

export const memberSlice = createSlice({
    name: 'member',
    initialState,
    reducers: {
        setCurrentMember: (state, action) => {
            state.currentMember = { ...action.payload };
        },
        setCheckingDeliveryArea: (state, action) => {
            const { status, index } = action.payload;
            state.data[index].isCheckingDeliveryArea = status;
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchAllMember.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(fetchAllMember.fulfilled, (state, action) => {
            // state.loadStatus = true;
            state.loading = false;

            const newData = { ...action.payload.data };
            state.data = [...newData.data];
            delete newData.data;
            state.paginate = newData;
        })
        .addCase(fetchAllMember.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(createNewMember.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(createNewMember.fulfilled, (state, action) => {
            state.loading = false;
            state.newMember = action.payload.data;
            state.error = null;
        })
        .addCase(createNewMember.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.response.data;
        })
        .addCase(updateMember.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(updateMember.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
        })
        .addCase(updateMember.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.response.data;
        })
        .addCase(deleteMember.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(deleteMember.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
        })
        .addCase(deleteMember.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.response.data;
        })
    }
});

export const { setCurrentMember, setCheckingDeliveryArea } = memberSlice.actions;
export default memberSlice.reducer;