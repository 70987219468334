import { createSlice } from '@reduxjs/toolkit';
import staticData from '../config/config';

const initialState = {
  rtlData: staticData.rtl,
  topMenu: staticData.topMenu,
  mode: staticData.mainTemplate,
  loading: false,
  rtlLoading: false,
  menuLoading: false,
  mainContentLoading: false,
  error: null,
};

export const themeLayoutSlice = createSlice({
  name: 'themeLayout',
  initialState,
});

export default themeLayoutSlice.reducer;