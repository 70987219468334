import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const RecipientCentralPage = lazy(() => import('../../container/pages/RecipientAddress/CentralCodAddressPage'));
const RecipientMemberPage = lazy(() => import('../../container/pages/RecipientAddress/RecipientAddressPage'));

function RecipientRoute() {
  return (
    <Routes>
      <Route path="central" element={<RecipientCentralPage />} />
      <Route path="member" element={<RecipientMemberPage />} />
      {/* <Route path="*" element={<NotFound />} /> */}
    </Routes>
  );
}

export default RecipientRoute;
