import { createAsyncThunk } from "@reduxjs/toolkit";
import { DataService } from "../config/dataService/dataService";

export const fetchAllAdmin = createAsyncThunk(
    "admin/fetch",
    async (filter, { rejectWithValue }) => {
        try {
            let url = '/member?filter=role.id||$eq||1';
            if (filter) {
                url += '&' + filter;
            }

            const response = await DataService.get(url);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }  
)

export const createNewAdmin = createAsyncThunk(
    "admin/create",
    async ({ data }, { rejectWithValue }) => {
        try {
            const response = await DataService.post('/member', data);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }  
)

export const updateAdmin = createAsyncThunk(
    "admin/update",
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const response = await DataService.patch('/member/' + id, data);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }  
)

export const deleteAdmin = createAsyncThunk(
    "admin/delete",
    async ({ id }, { rejectWithValue }) => {
        try {
            const response = await DataService.delete('/member/' + id);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }  
)