import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./authSlice";
import bankSlice from "./bankSlice";
import settingSlice from "./settingSlice";
import themeLayoutSlice from "./themeLayoutSlice";
import provinceSlice from "./provinceSlice";
import districtSlice from "./districtSlice";
import subDistrictSlice from "./subDistrictSlice";
import centralCodAddressSlice from "./centralCodAddressSlice";
import uploadSlice from "./uploadSlice";
import adminSlice from "./adminSlice";
import mainSlice from "./mainSlice";
import memberSlice from "./memberSlice";
import placeSlice from "./placeSlice";
import codBankSlice from "./codBankSlice";
import shippingSlice from "./shippingSlice";

export const store = configureStore({
    reducer: {
        main: mainSlice,
        auth: authSlice,
        member: memberSlice,
        themeLayout: themeLayoutSlice,
        bank: bankSlice,
        setting: settingSlice,
        province: provinceSlice,
        district: districtSlice,
        subDistrict: subDistrictSlice,
        recipientAddress: centralCodAddressSlice,
        upload: uploadSlice,
        admin: adminSlice,
        place: placeSlice,
        codBank: codBankSlice,
        shipping: shippingSlice
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})