import { createSlice } from '@reduxjs/toolkit';
import { createNewShipping, deleteShipping, fetchAllShipping, updateShipping, updateStatusShipping } from '../services/shippingService';

const initialState = {
    data: [],
    paginate: null,
    currentShipping: null,
    loading: false,
    error: null,
    newShipping: null,
    selectedRowKeys: [],
    selectedRecordRowKeys: []
};

export const shippingSlice = createSlice({
    name: 'shipping',
    initialState,
    reducers: {
        setCurrentShipping: (state, action) => {
            state.currentShipping = { ...action.payload };
        },
        setIsShippingLoading: (state, action) => {
            state.loading = action.payload;
        },
        setSelectedRowKeys: (state, action) => {
            state.selectedRowKeys = action.payload;
        },
        setSelectedRecordRowKeys: (state, action) => {
            state.selectedRecordRowKeys = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllShipping.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchAllShipping.fulfilled, (state, action) => {
                // state.loadStatus = true;
                state.loading = false;

                const newData = { ...action.payload.data };
                state.data = [...newData.data];
                delete newData.data;
                state.paginate = newData;
            })
            .addCase(fetchAllShipping.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(createNewShipping.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(createNewShipping.fulfilled, (state, action) => {
                state.loading = false;
                state.newShipping = action.payload.data;
                state.error = null;
            })
            .addCase(createNewShipping.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.response.data;
            })
            .addCase(updateShipping.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(updateShipping.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
            })
            .addCase(updateShipping.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.response.data;
            })
            .addCase(deleteShipping.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(deleteShipping.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
            })
            .addCase(deleteShipping.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.response.data;
            })
            .addCase(updateStatusShipping.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(updateStatusShipping.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
            })
            .addCase(updateStatusShipping.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.response.data;
            })
    }
});

export const { setCurrentShipping, setIsShippingLoading, setSelectedRowKeys, setSelectedRecordRowKeys } = shippingSlice.actions;
export default shippingSlice.reducer;