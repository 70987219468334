import { createSlice } from "@reduxjs/toolkit"
import { createNewAdmin, deleteAdmin, fetchAllAdmin, updateAdmin } from "../services/adminService";

const initialState = {
    data: [],
    paginate: null,
    currentAdmin: null,
    loading: false,
    error: null,
    newAdmin: null
}

export const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        setCurrentAdmin: (state, action) => {
            state.currentAdmin = { ...action.payload };
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchAllAdmin.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(fetchAllAdmin.fulfilled, (state, action) => {
            // state.loadStatus = true;
            state.loading = false;

            const newData = { ...action.payload.data };
            state.data = [...newData.data];
            delete newData.data;
            state.paginate = newData;
        })
        .addCase(fetchAllAdmin.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(createNewAdmin.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(createNewAdmin.fulfilled, (state, action) => {
            state.loading = false;
            state.newAdmin = action.payload.data;
            state.error = null;
        })
        .addCase(createNewAdmin.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.response.data;
        })
        .addCase(updateAdmin.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(updateAdmin.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
        })
        .addCase(updateAdmin.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.response.data;
        })
        .addCase(deleteAdmin.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(deleteAdmin.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
        })
        .addCase(deleteAdmin.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.response.data;
        })
    }
});

export const { setCurrentAdmin } = adminSlice.actions;
export default adminSlice.reducer;