import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import AdminPage from '../../container/pages/AdminPage';

const MainPage = lazy(() => import('../../container/pages/MainPage'));
const SettingPage = lazy(() => import('../../container/pages/SettingPage'));
// const MemberPage = lazy(() => import('../../container/pages/MemberPage'));
const UploadTemplatePage = lazy(() => import('../../container/pages/UploadTemplatePage'));
const BankPage = lazy(() => import('../../container/pages/BankPage'));
const ShippingPage = lazy(() => import('../../container/pages/ShippingPage/ShippingPage'));

function PagesRoute() {
  return (
    <Routes>
      <Route index element={<MainPage />} />
      <Route path="main" element={<MainPage />} />
      <Route path="shipping" element={<ShippingPage />} />
      {/* <Route path="member" element={<MemberPage />} /> */}
      <Route path="admin" element={<AdminPage />} />
      <Route path="setting" element={<SettingPage />} />
      <Route path="upload-template" element={<UploadTemplatePage />} />
      <Route path="bank" element={<BankPage />} />
    </Routes>
  );
}

export default PagesRoute;
