import { createSlice } from '@reduxjs/toolkit';
import { createNewCodBank, deleteCodBank, fetchAllCodBank, updateCodBank } from '../services/codBankService';

const initialState = {
    data: [],
    paginate: null,
    currentCodBank: null,
    loading: false,
    error: null,
    newCodBank: null
};

export const codBankSlice = createSlice({
    name: 'codBank',
    initialState,
    reducers: {
        setCurrentCodBank: (state, action) => {
            state.currentCodBank = {...action.payload};
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllCodBank.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchAllCodBank.fulfilled, (state, action) => {
                // state.loadStatus = true;
                state.loading = false;

                const newData = { ...action.payload.data };
                state.data = [...newData.data];
                delete newData.data;
                state.paginate = newData;
            })
            .addCase(fetchAllCodBank.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(createNewCodBank.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(createNewCodBank.fulfilled, (state, action) => {
                state.loading = false;
                state.newCodBank = action.payload.data;
                state.error = null;
            })
            .addCase(createNewCodBank.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.response.data;
            })
            .addCase(updateCodBank.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(updateCodBank.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
            })
            .addCase(updateCodBank.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.response.data;
            })
            .addCase(deleteCodBank.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(deleteCodBank.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
            })
            .addCase(deleteCodBank.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.response.data;
            })
    }
});

export const { setCurrentCodBank } = codBankSlice.actions;
export default codBankSlice.reducer;