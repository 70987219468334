import { createAsyncThunk } from "@reduxjs/toolkit";
import { DataService } from "../config/dataService/dataService";

export const fetchAllBanks = createAsyncThunk(
    "bank/fetch",
    async (filter, { rejectWithValue }) => {
        try {
            let url = '/bank'
            if (filter) {
                url += '?' + filter;
            }

            const response = await DataService.get(url);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }  
)

export const createNewBank = createAsyncThunk(
    "bank/create",
    async ({ data }, { rejectWithValue }) => {
        try {
            const response = await DataService.post('/bank', data);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }  
)

export const updateBank = createAsyncThunk(
    "bank/update",
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const response = await DataService.patch('/bank/' + id, data);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }  
)

export const deleteBank = createAsyncThunk(
    "bank/delete",
    async ({ id }, { rejectWithValue }) => {
        try {
            const response = await DataService.delete('/bank/' + id);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }  
)