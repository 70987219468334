import { createSlice } from "@reduxjs/toolkit";
import { createNewProvince, deleteProvince, fetchAllProvince, updateProvince } from "../services/provinceService";

const initialState = {
    data: [],
    paginate: null,
    currentProvince: null,
    loading: false,
    error: null,
    newProvince: null
};

export const provinceSlice = createSlice({
    name: 'province',
    initialState,
    reducers: {
        setCurrentProvince: (state, action) => {
            state.currentProvince = { ...action.payload };
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchAllProvince.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(fetchAllProvince.fulfilled, (state, action) => {
            // state.loadStatus = true;
            state.loading = false;

            const newData = { ...action.payload.data };
            state.data = [...newData.data];
            delete newData.data;
            state.paginate = newData;
        })
        .addCase(fetchAllProvince.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(createNewProvince.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(createNewProvince.fulfilled, (state, action) => {
            state.loading = false;
            state.newProvince = action.payload.data;
            state.error = null;
        })
        .addCase(createNewProvince.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.response.data;
        })
        .addCase(updateProvince.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(updateProvince.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
        })
        .addCase(updateProvince.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.response.data;
        })
        .addCase(deleteProvince.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(deleteProvince.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
        })
        .addCase(deleteProvince.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.response.data;
        })
    }
});

export const { setCurrentProvince } = provinceSlice.actions;
export default provinceSlice.reducer;