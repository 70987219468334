import { createSlice } from "@reduxjs/toolkit";
import { createNewDistrict, deleteDistrict, fetchAllDistrict, updateDistrict } from "../services/districtService";

const initialState = {
    data: [],
    paginate: null,
    currentDistrict: null,
    loading: false,
    error: null,
    newDistrict: null
};

export const districtSlice = createSlice({
    name: 'district',
    initialState,
    reducers: {
        setCurrentDistrict: (state, action) => {
            state.currentDistrict = { ...action.payload };
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchAllDistrict.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(fetchAllDistrict.fulfilled, (state, action) => {
            // state.loadStatus = true;
            state.loading = false;

            const newData = { ...action.payload.data };
            state.data = [...newData.data];
            delete newData.data;
            state.paginate = newData;
        })
        .addCase(fetchAllDistrict.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(createNewDistrict.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(createNewDistrict.fulfilled, (state, action) => {
            state.loading = false;
            state.newDistrict = action.payload.data;
            state.error = null;
        })
        .addCase(createNewDistrict.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.response.data;
        })
        .addCase(updateDistrict.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(updateDistrict.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
        })
        .addCase(updateDistrict.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.response.data;
        })
        .addCase(deleteDistrict.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(deleteDistrict.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
        })
        .addCase(deleteDistrict.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.response.data;
        })
    }
});


export const { setCurrentDistrict } = districtSlice.actions;
export default districtSlice.reducer;