import { createAsyncThunk } from "@reduxjs/toolkit";
import { DataService } from "../config/dataService/dataService";

export const searchPlace = createAsyncThunk(
    "member/map/search/place",
    async ({ searchText }, { rejectWithValue }) => {
        try {
            const url = `https://search.longdo.com/mapsearch/json/search?keyword=${searchText}&limit=20&key=${process.env.REACT_APP_LONGDO_MAP_KEY}`
            const response = await DataService.externalGet(url);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    } 
)