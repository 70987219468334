import { createAsyncThunk } from "@reduxjs/toolkit";
import { DataService } from "../config/dataService/dataService";

export const fetchAllShipping = createAsyncThunk(
    "shipping/fetch",
    async (filter, { rejectWithValue }) => {
        try {
            let url = '/shipping'
            if (filter) {
                url += '?' + filter;
            }

            const response = await DataService.get(url);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }  
)

export const createNewShipping = createAsyncThunk(
    "shipping/create",
    async ({ data }, { rejectWithValue }) => {
        try {
            const response = await DataService.post('/shipping', data);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }  
)

export const updateShipping = createAsyncThunk(
    "shipping/update",
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const response = await DataService.patch('/shipping/' + id, data);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }  
)

export const deleteShipping = createAsyncThunk(
    "shipping/delete",
    async ({ id }, { rejectWithValue }) => {
        try {
            const response = await DataService.delete('/shipping/' + id);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }  
)

export const updateStatusShipping = createAsyncThunk(
    "shipping/update-status",
    async ({ id, isConfirm }, { rejectWithValue }) => {
        try {
            const response = await DataService.post('/shipping/update-status', { id, isConfirm });
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }  
)

export const overviewShipping = createAsyncThunk(
    "shipping/overview",
    async ({ memberType, startDate, endDate }, { rejectWithValue }) => {
        try {

            let payload = {
                startDate,
                endDate
            };

            if (memberType) payload.memberType = memberType;
            const response = await DataService.post('/shipping/overview', payload);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }  
)
