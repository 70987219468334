import { UilHome, UilSetting, UilUsersAlt, UilBuilding, UilFileShareAlt, UilCoins, UilTruck, UilHouseUser, UilUserPlus } from '@iconscout/react-unicons';

import { Menu } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import propTypes from 'prop-types';

function MenuItems({ toggleCollapsed }) {
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu } = useSelector((state) => {
    return {
      topMenu: state.themeLayout.topMenu,
    };
  });

  const path = '/admin';

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const items = [
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/main`}>
        หน้าหลัก
      </NavLink>,
      'main',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/main`}>
          <UilHome />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/shipping`}>
        พัสดุที่จัดส่ง
      </NavLink>,
      'shipping',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/shipping`}>
          <UilTruck />
        </NavLink>
      ),
    ),
    getItem(
      'สมาชิก',
      'member',
      !topMenu && <UilUsersAlt />, [
        getItem(
          <NavLink onClick={toggleCollapsed} to={`${path}/member/index`}>
            ข้อมูลสมาชิก
          </NavLink>,
          'member-index',
          null,
        ),
        getItem(
          <NavLink onClick={toggleCollapsed} to={`${path}/member/add`}>
            เพิ่มสมาชิก
          </NavLink>,
          'member-add',
          null,
        ),
      ]
    ),
    getItem(
      'ข้อมูลที่อยู่ปลายทาง',
      'recipient',
      !topMenu && <UilBuilding />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/recipient/central`}>
          ที่อยู่ปลายทางส่วนกลาง
        </NavLink>,
        'recipient-central',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/recipient/member`}>
          ที่อยู่ปลายทางสมาชิก
        </NavLink>,
        'recipient-member',
        null,
      ),
    ]),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/upload-template`}>
        อัพโหลด Template
      </NavLink>,
      '/upload-template',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}//upload-template`}>
          <UilFileShareAlt />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/admin`}>
        ผู้ดูแลระบบ
      </NavLink>,
      'admin',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/admin`}>
          <UilUserPlus />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/setting`}>
        ตั้งค่า
      </NavLink>,
      'setting',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/setting`}>
          <UilSetting />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/bank`}>
        ธนาคาร
      </NavLink>,
      '/bank',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}//bank`}>
          <UilCoins />
        </NavLink>
      ),
    ),
    getItem(
      'ที่อยู่',
      'address',
      !topMenu && <UilHouseUser />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/address/province`}>
          จังหวัด
        </NavLink>,
        'province',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/address/district`}>
          อำเภอ/เขต
        </NavLink>,
        'district',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/address/sub-district`}>
          ตำบล/แขวง
        </NavLink>,
        'sub-district',
        null,
      ),
    ]),
  ];

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
            `${mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
            }`,
          ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={items}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
