import { createSlice } from '@reduxjs/toolkit';
import { login } from '../services/authService';
import { getItem, removeItem, setItem } from '../utility/localStorageControl';

const initialState = {
    login: getItem("logedIn"),
    user: null,
    loading: false,
    error: null,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            removeItem('logedIn');
            removeItem('access_token');
            removeItem('id');
            removeItem('fullname');
            removeItem('role');
            removeItem('showEstimateCost');
            state.loading = false;
            state.login = undefined;
            state.user = null;
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.error = null;
                state.loading = false;
                state.user = action.payload.data;
                state.login = true;
                setItem('logedIn', true);
                setItem('access_token', action.payload.data.token);
                setItem('id', action.payload.data.id);
                setItem('fullname', action.payload.data.fullname);
                setItem('role', action.payload.data.role);
                setItem('showEstimateCost', action.payload.data.showEstimateCost);
            })
            .addCase(login.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.response.data;
            })
    }
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;