import { createSlice } from "@reduxjs/toolkit";
import { activeAreaStatus, createNewSubDistrict, deleteSubDistrict, fetchAllSubDistrict, updateSubDistrict } from "../services/subDistrictService";

const initialState = {
    data: [],
    paginate: null,
    currentSubDistrict: null,
    loading: false,
    error: null,
    newSubDistrict: null
};

export const subDistrictSlice = createSlice({
    name: 'subDistrict',
    initialState,
    reducers: {
        setCurrentSubDistrict: (state, action) => {
            state.currentSubDistrict = { ...action.payload };
        },
        setActiveAreaSubDistrict: (state, action) => {
            const { status, indexSubDistrictChange } = action.payload;
            state.data[indexSubDistrictChange].deliveryAreaStatus = status;
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchAllSubDistrict.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(fetchAllSubDistrict.fulfilled, (state, action) => {
            // state.loadStatus = true;
            state.loading = false;

            const newData = { ...action.payload.data };
            state.data = [...newData.data];
            delete newData.data;
            state.paginate = newData;
        })
        .addCase(fetchAllSubDistrict.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(createNewSubDistrict.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(createNewSubDistrict.fulfilled, (state, action) => {
            state.loading = false;
            state.newSubDistrict = action.payload.data;
            state.error = null;
        })
        .addCase(createNewSubDistrict.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.response.data;
        })
        .addCase(updateSubDistrict.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(updateSubDistrict.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
        })
        .addCase(updateSubDistrict.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.response.data;
        })
        .addCase(deleteSubDistrict.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(deleteSubDistrict.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
        })
        .addCase(deleteSubDistrict.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.response.data;
        })
        .addCase(activeAreaStatus.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(activeAreaStatus.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
        })
        .addCase(activeAreaStatus.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.response.data;
        })
    }
});

export const { setCurrentSubDistrict, setActiveAreaSubDistrict } = subDistrictSlice.actions;
export default subDistrictSlice.reducer;