import React from 'react';
import { Row, Col, Spin, Form, Empty, Table, Modal, Input, Space } from 'antd';
import { PageHeader } from '../../components/page-headers/page-headers';
import { BasicFormWrapper, BorderLessHeading, Main, TableWrapper, VerticalFormStyleWrap } from '../styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import { Button } from '../../components/buttons/buttons';
import { UserTableStyleWrapper } from './style';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { UilEdit, UilTrashAlt, UilEye, UilEyeSlash, UilExclamationTriangle, UilSearch } from '@iconscout/react-unicons';
import { createNewAdmin, deleteAdmin, fetchAllAdmin, updateAdmin } from '../../services/adminService';
import { useEffect } from 'react';
import Notification from '../../components/notification/Notification';
import { setCurrentAdmin } from '../../stores/adminSlice';

const adminColumns = (onEditAdmin = () => { }, onConfirmDeleteAdmin = () => { }) => {
  const columns = [
    {
      title: <b>ลำดับ</b>,
      dataIndex: 'id',
      key: 'id',
      render: (text, record, index) => {
        return index + 1;
      }
    },
    {
      title: <b>ชื่อ-นามสกุล</b>,
      dataIndex: 'fullname',
      key: 'fullname',
    },
    {
      title: <b>ชื่อผู้ใช้</b>,
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: <b>จัดการ</b>,
      dataIndex: 'action',
      key: 'action',
      render: (text, record, index) => {
        return (
          <div className="table-actions">
            <Button className="btn-icon" type="info" to="#" shape="circle" onClick={(() => onEditAdmin(record))}>
              <UilEdit />
            </Button>
            <Button className="btn-icon" type="danger" to="#" shape="circle" onClick={() => onConfirmDeleteAdmin(record)}>
              <UilTrashAlt />
            </Button>
          </div>
        );
      }
    },
  ];

  return columns;
}

function AdminPage() {

  const dispatch = useDispatch();
  const [formAdd] = Form.useForm();
  const [formEdit] = Form.useForm();
  const [openAddNewAdminModal, setOpenAddNewAdminModal] = useState(false);
  const [openEditAdminModal, setOpenEditAdminModal] = useState(false);
  const [searchText, setSearchText] = useState('');

  const { data, isLoading, currentAdmin } = useSelector((state) => {
    return {
      data: state.admin.data,
      error: state.admin.error,
      isLoading: state.admin.loading,
      currentAdmin: state.admin.currentAdmin
    }
  });

  useEffect(() => {
    dispatch(fetchAllAdmin());
  }, [dispatch]);

  const onAddNewAdmin = () => {
    formAdd.resetFields();
    setOpenAddNewAdminModal(true);
  }

  const onConfirmAddNewAdmin = () => {
    Modal.confirm({
      title: 'Confirm',
      icon: <UilExclamationTriangle />,
      content: 'ต้องการบันทึกข้อมูลใช่หรือไม่?',
      okText: 'บันทึก',
      cancelText: 'ยกเลิก',
      onOk: () => onSaveNewAdmin()
    });
  }

  const onSaveNewAdmin = async () => {
    formAdd.validateFields().then(async (values) => {
      values.role = 1;
      values.telno = values.username;

      try {
        await dispatch(createNewAdmin({ data: values })).unwrap();
        setOpenAddNewAdminModal(false);
        dispatch(fetchAllAdmin());
        Notification("success", "บันทึกข้อมูลเรียบร้อยแล้ว");
      } catch (error) {
        setOpenAddNewAdminModal(false);
        Notification("error", "ไม่สามารถบันทึกข้อมูลได้เนื่องจากชื่อผู้ใช้ซ้ำ กรุณาลองใหม่");
      }
    });
  }

  const onEditAdmin = (record) => {
    formEdit.resetFields();
    formEdit.setFieldsValue(record);
    dispatch(setCurrentAdmin(record));
    setOpenEditAdminModal(true);
  }

  const onConfirmEditAdmin = () => {
    Modal.confirm({
      title: 'Confirm',
      icon: <UilExclamationTriangle />,
      content: 'ต้องการบันทึกข้อมูลใช่หรือไม่?',
      okText: 'บันทึก',
      cancelText: 'ยกเลิก',
      onOk: () => onSaveEditAdmin()
    });
  }

  const onSaveEditAdmin = () => {
    formEdit.validateFields().then(async (values) => {
      delete values.username;
      if (!values.password) delete values.password;

      try {
        await dispatch(updateAdmin({ id: currentAdmin.id, data: values })).unwrap();
        setOpenEditAdminModal(false);
        dispatch(fetchAllAdmin());
        Notification("success", "บันทึกข้อมูลเรียบร้อยแล้ว");
      } catch (error) {
        setOpenEditAdminModal(false);
        Notification("error", "ไม่สามารถบันทึกข้อมูลได้ กรุณาลองใหม่ภายหลัง");
      }
    });
  }

  const onConfirmDeleteAdmin = (record) => {
    if (data.length === 1) {
      Notification("error", "ไม่สามารถลบข้อมูลได้ เนื่องจากเหลือผู้ดูแลระบบเพียงคนเดียว");
      return;
    }

    Modal.confirm({
      title: 'Confirm',
      icon: <UilExclamationTriangle />,
      content: 'ต้องการลบผู้ดูแลระบบ ' + record.fullname + ' ใช่หรือไม่?',
      okText: 'บันทึก',
      cancelText: 'ยกเลิก',
      onOk: () => onSaveDeleteAdmin(record)
    });
  }

  const onSaveDeleteAdmin = async (record) => {
    try {
      await dispatch(deleteAdmin({ id: record.id })).unwrap();
      dispatch(fetchAllAdmin());
      Notification("success", "ลบข้อมูลเรียบร้อยแล้ว");
    } catch (error) {
      Notification("error", "ไม่สามารถลบข้อมูลได้ กรุณาลองใหม่ภายหลัง");
    }
  }

  const search = () => {
    if (searchText !== '') {
      let filter = 'filter=fullname||$cont||' + searchText;
      filter += '&or=username||$cont||' + searchText;
      return dispatch(fetchAllAdmin(filter));
    }

    return dispatch(fetchAllAdmin());
  }

  return (
    <>
      <PageHeader
        className="ninjadash-page-header-main"
        title="ผู้ดูแลระบบ"
        buttons={[
          <Button key={1} className="btn-add_new" type="primary" size={'extra-small'} onClick={() => onAddNewAdmin()}>
            + เพิ่มผู้ดูแลระบบ
          </Button>,
        ]}
      />
      <Main>
        <Spin spinning={isLoading}>
          <Row gutter={25}>
            <Col sm={24} xs={24}>
              <Cards headless>
                <Space style={{ marginBottom: 5 }}>
                  <Input placeholder="ค้นหาด้วยชื่อ-นามสกุล/ชื่อผู้ใช้" size="default" onChange={(e) => setSearchText(e.target.value)} style={{ padding: '7px 15px' }} />
                  <Button key={2} type="primary" size="default" onClick={() => search()} style={{ height: 35 }}>
                    <UilSearch /> ค้นหา
                  </Button>
                </Space>
                <BorderLessHeading>
                  <UserTableStyleWrapper>
                    <TableWrapper className="table-responsive">
                      <Table
                        // bordered
                        locale={{ emptyText: <Empty description={<b>ไม่มีข้อมูล</b>} /> }}
                        size={'small'}
                        columns={adminColumns(onEditAdmin, onConfirmDeleteAdmin)}
                        dataSource={data}
                        rowKey={'id'}
                        pagination={{ defaultPageSize: 30, position: ['bottomRight'], showTotal: (total, range) => `${range[0]}-${range[1]} จาก ${total} ข้อมูล`, }}
                      />
                    </TableWrapper>
                  </UserTableStyleWrapper>
                </BorderLessHeading>
              </Cards>
            </Col>
          </Row>

          <Modal
            type={'success'}
            title={'เพิ่มผู้ดูแลระบบ'}
            maskClosable={false}
            width={1000}
            open={openAddNewAdminModal}
            onOk={() => onConfirmAddNewAdmin()}
            okText={'บันทึก'}
            onCancel={() => setOpenAddNewAdminModal(false)}
            cancelText={'ยกเลิก'}
          >
            <BasicFormWrapper>
              <VerticalFormStyleWrap>
                <Form name="ninjadash-vertical-form" layout="vertical" form={formAdd}>
                  <Form.Item
                    name="fullname"
                    label="ชื่อ-นามสกุล"
                    rules={[
                      {
                        required: true,
                        message: 'กรุณาระบุชื่อ-นามสกุล'
                      },
                    ]}
                  >
                    <Input placeholder="ชื่อ-นามสกุล" />
                  </Form.Item>
                  <Form.Item
                    name="username"
                    label="ชื่อผู้ใช้"
                    rules={[
                      {
                        required: true,
                        message: 'กรุณาระบุชื่อผู้ใช้'
                      },
                    ]}
                  >
                    <Input placeholder="ชื่อผู้ใช้" />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    label="รหัสผ่าน"
                    rules={[
                      {
                        required: true,
                        message: 'กรุณาระบุรหัสผ่าน'
                      },
                    ]}
                  >
                    <Input.Password placeholder="รหัสผ่าน" iconRender={(visible) => (visible ? <UilEye /> : <UilEyeSlash />)} />
                  </Form.Item>
                </Form>
              </VerticalFormStyleWrap>
            </BasicFormWrapper>
          </Modal>

          <Modal
            type={'success'}
            title={'แก้ไขผู้ดูแลระบบ'}
            maskClosable={false}
            width={1000}
            open={openEditAdminModal}
            onOk={() => onConfirmEditAdmin()}
            okText={'บันทึก'}
            onCancel={() => setOpenEditAdminModal(false)}
            cancelText={'ยกเลิก'}
          >
            <BasicFormWrapper>
              <VerticalFormStyleWrap>
                <Form name="ninjadash-vertical-form" layout="vertical" form={formEdit}>
                  <Form.Item
                    name="fullname"
                    label="ชื่อ-นามสกุล"
                    rules={[
                      {
                        required: true,
                        message: 'กรุณาระบุชื่อ-นามสกุล'
                      },
                    ]}
                  >
                    <Input placeholder="ชื่อ-นามสกุล" />
                  </Form.Item>
                  <Form.Item
                    name="username"
                    label="ชื่อผู้ใช้"
                  >
                    <Input placeholder="ชื่อผู้ใช้" disabled />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    label="รหัสผ่าน"
                  >
                    <Input.Password placeholder="โปรดระบุหากต้องการเปลี่ยนรหัสผ่าน" iconRender={(visible) => (visible ? <UilEye /> : <UilEyeSlash />)} />
                  </Form.Item>
                </Form>
              </VerticalFormStyleWrap>
            </BasicFormWrapper>
          </Modal>
        </Spin>
      </Main>
    </>
  );
}

export default AdminPage;
